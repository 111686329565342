.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background-color: #ff6666;
  border-radius: 4px;
}

.warning {
  margin-top: 1rem;
  color: #ff6666;
}