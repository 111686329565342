.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;
  padding: 0 20px;

  border-bottom: 1px solid #191e23;
  background: linear-gradient(to bottom, #2c3742 0%, #28303a 100%);

  &_logo > img {
    height: 25px;
    cursor: pointer;
  } 

  &_info-box {
    display: flex;
    align-items: center;

    height: 100%;
  }

  &_link {
    font-size: .9rem;
    padding: 0 15px;
    color: #d6d6d6;
    outline: 0px;
    transition: color .1s ease-out;

    &:not(:first-child) {
      border-left: 1px solid grey;
    }

    &:not(:last-child):hover {
      color: white;
    }
  }
}