*, *::before, *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
  font-weight: normal;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h6 {
  font-size: 1rem;
}

a {
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    cursor: pointer;
  }
}