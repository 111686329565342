.list-item {
  cursor: pointer;
  border: none;
  border-left: 2px solid #ccc;
  border-radius: 0;
  padding: 5px 10px;
  margin-bottom: 10px;

  &:hover {
    background-color: #ccc;
  }

  &_title {
    color: black;
    font-size: 13px;
    font-weight: 600;
  }

  &_subtitle--blue {
    color: #007AC3;
  }

  &_subtitle--grey {
    color: #5A6A6A;
  }
}