.button {
  border: 0;
  border-radius: 3px;

  cursor: pointer;

  font-size: 12px;
  font-weight: bold;;

  align-items: center;
  justify-content: center;
  display: inline-flex;
  line-height: 1.5;
  padding: 0.75rem 2.5rem;

  background-color: #007bff;
  border-color: #007bff;
  color: white;

  &:hover {
    text-decoration: none;
  }

  &:disabled, &[disabled] {
    cursor: no-drop;
    opacity: .7;
  }

  &--sm {
    padding: 0.375rem 0.75rem;
  }
}
