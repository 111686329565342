.resolver {
  display: flex;
  height: 100%;
  overflow: hidden;

  &_reader {
    max-width: 75%;
    flex: 1 0 auto;
    border: none;
    margin: 0;
    padding: 0;
    background-color: #525659;
  }

  &_sidebar-container {
    flex: 0 0 35%;
    max-width: 35%;
    border-left: 1px solid #ddd;
  }

  &_sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &_sidebar-header {
    padding: 10px 15px 5px 15px;
    flex: 0 0 auto;
    border-bottom: 1px solid #ddd;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    & > * {
      margin-bottom: 5px;
    }
  }

  &_sidebar-pagination {
    display: flex;
    align-items: center;
  }

  &_sidebar-scrollbar {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: #eee;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,.1);
      outline: 1px solid slategrey;
      border-radius: 5px;
      outline: none;
      border: none;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: lightgray;
    }

    &::-webkit-scrollbar-corner {
      background-color: #eee;
    }
  }

  &_sidebar-footer {
    padding: 15px;
    flex: 0 0 auto;

    display: flex;
    flex-direction: column;

    border-top: 1px solid #ddd;
  }

  &_sidebar-buttons {
    display: flex;
    justify-content: center;

    & > * {
      margin: 0 .3rem;
    }
  }
}