.badge {
  font-size: 10px;
  background-color: #e9e9e9;
  padding: 5px;
  margin-right: 5px;
  display: inline-block;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &--link {
    cursor: pointer;
    color: white;
    background-color: #007bff;
    outline:none;

    &:disabled, &[disabled] {
      cursor: no-drop;
      opacity: .7;
    }

  }
}