.center-horizontal-vertical {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}